import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p className="audiowide-regular">NVMNO</p>
        <p className="audiowide-regular text-red-500">
          Norsk Videospill Museum
        </p>
      </header>
    </div>
  );
}

export default App;
